import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "mobile-spacing-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "skeleton-box w-100"
}
const _hoisted_5 = ["id", "value"]
const _hoisted_6 = {
  key: 1,
  class: "input-group mb-3"
}
const _hoisted_7 = {
  key: 0,
  class: "skeleton-box w-100"
}
const _hoisted_8 = ["id", "readonly", "placeholder", "aria-label", "v-maska"]
const _hoisted_9 = { class: "input-group-append" }
const _hoisted_10 = { class: "input-group-text" }
const _hoisted_11 = ["id"]
const _hoisted_12 = {
  key: 0,
  class: "skeleton-box w-100"
}
const _hoisted_13 = ["identifier", "value", "direction", "disabled", "tabindex", ".localization", ".dateAdapter", "min", "max"]
const _hoisted_14 = ["id"]
const _hoisted_15 = {
  key: 0,
  class: "skeleton-box w-100"
}
const _hoisted_16 = ["id", "tabindex", "readonly", "placeholder", "aria-label", "v-maska"]
const _hoisted_17 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_asterisk = _resolveComponent("validation-asterisk")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_validation_provider = _resolveComponent("validation-provider")!

  return (_openBlock(), _createBlock(_component_validation_provider, {
    ref: "provider",
    rules: _ctx.formRules,
    name: _ctx.inputId,
    label: _ctx.name,
    vid: _ctx.validationId ? _ctx.validationId : _ctx.inputId,
    modelValue: _ctx.validatedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.validatedValue) = $event))
  }, {
    default: _withCtx(({ field, errors }) => [
      _createElementVNode("label", {
        for: _ctx.inputId,
        class: _normalizeClass({ 'sr-only': _ctx.hideLabel })
      }, [
        _createTextVNode(_toDisplayString(_ctx.label || _ctx.name) + " ", 1),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_validation_asterisk, {
              key: 0,
              rules: _ctx.formRules,
              crossValues: _ctx.crossValues,
              ruleKey: _ctx.ruleKey
            }, null, 8, ["rules", "crossValues", "ruleKey"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "link")
      ], 10, _hoisted_1),
      (_ctx.calculated)
        ? (_openBlock(), _createElementBlock("small", {
            key: 0,
            class: "form-text text-muted",
            title: _ctx.$t('calculated')
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: "text-grey",
              icon: ['far', 'exclamation-circle'],
              "fixed-width": ""
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.calculatedText), 1)
          ], 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_3, [
        (_ctx.isMasked)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4))
                : (_openBlock(), _createElementBlock("input", {
                    key: 1,
                    type: "text",
                    class: _normalizeClass({ 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.isReadOnly(_ctx.readonly), 'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly) }),
                    id: _ctx.inputId,
                    disabled: true,
                    readonly: true,
                    value: _ctx.modelValue
                  }, null, 10, _hoisted_5))
            ], 64))
          : (_ctx.append)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7))
                  : (_openBlock(), _createElementBlock("input", _mergeProps({
                      key: 1,
                      id: _ctx.inputId,
                      type: "text",
                      class: { 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.isReadOnly(_ctx.readonly), 'form-control-plaintext': _ctx.isReadOnly(_ctx.readonly) }
                    }, field, {
                      readonly: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
                    }, _toHandlers(_ctx.maskedInputEvents(), true), {
                      placeholder: _ctx.getSettings.placeholder,
                      "aria-label": _ctx.getSettings.ariaLabel,
                      "v-maska": _ctx.getSettings.vMask
                    }), null, 16, _hoisted_8)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.appendText), 1)
                ]),
                (errors[0])
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: "invalid-feedback",
                      id: `${_ctx.inputId}-error`
                    }, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['far', 'exclamation-circle'],
                        "fixed-width": ""
                      }),
                      _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                    ], 8, _hoisted_11))
                  : _createCommentVNode("", true)
              ]))
            : (!_ctx.legacy)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_12))
                    : (_openBlock(), _createElementBlock("duet-date-picker", _mergeProps({
                        key: 1,
                        ref: "myTestingPicker",
                        identifier: _ctx.inputId,
                        type: "text",
                        value: _ctx.datePickerValue,
                        direction: _ctx.direction || 'left',
                        class: { 'is-invalid': !_ctx.disabled && errors[0] },
                        disabled: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled)
                      }, _toHandlers(_ctx.maskedInputEvents(), true), {
                        tabindex: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled) ? '0': '-1',
                        ".localization": _ctx.getLocalizationSettingsForDuet,
                        ".dateAdapter": _ctx.dateAdapter(),
                        "aria-label": "getSettings.ariaLabel",
                        min: _ctx.getMinDateLimit,
                        max: _ctx.getMaxDateLimit
                      }), null, 48, _hoisted_13)),
                  (errors[0])
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: "invalid-feedback",
                        id: `${_ctx.inputId}-error`
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'exclamation-circle'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                      ], 8, _hoisted_14))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15))
                    : (_openBlock(), _createElementBlock("input", _mergeProps({
                        key: 1,
                        id: _ctx.inputId,
                        type: "text",
                        class: { 'is-invalid': !_ctx.disabled && errors[0], 'form-control': !_ctx.readonly, 'form-control-plaintext': _ctx.readonly }
                      }, field, {
                        tabindex: _ctx.isReadOnly(_ctx.readonly||_ctx.disabled) ? '0': '-1',
                        readonly: _ctx.isReadOnly(_ctx.disabled||_ctx.readonly)
                      }, _toHandlers(_ctx.maskedInputEvents(), true), {
                        placeholder: _ctx.getSettings.placeholder,
                        "aria-label": _ctx.getSettings.ariaLabel,
                        "v-maska": _ctx.getSettings.vMask
                      }), null, 16, _hoisted_16)),
                  (errors[0] && !_ctx.disabled)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: "invalid-feedback",
                        id: `${_ctx.inputId}-error`
                      }, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: ['far', 'exclamation-circle'],
                          "fixed-width": ""
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.translateError(errors, _ctx.label || _ctx.name)), 1)
                      ], 8, _hoisted_17))
                    : _createCommentVNode("", true)
                ], 64))
      ])
    ]),
    _: 3
  }, 8, ["rules", "name", "label", "vid", "modelValue"]))
}